import { useContext, useState } from "react";
import FileUploader from "../common/dragAndDrop";
import AppContext from "../../context/AppContext";
import notification from "../../helpers/notification";
import ApiService from "../../api/ApiService";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function NeedHelp() {
  const { t, i18n } = useTranslation();
  const { user } = useContext(AppContext);
  const [formData, setFormData] = useState({ subject: "", message: "" });
  const [uploadedFile, setUploadedFile] = useState("");

  const HandleChangeUpdateForm = (key, value) => {
    const tempData = { ...formData };
    tempData[key] = value;
    setFormData(tempData);
  };

  const handleSubmitFormData = async (e) => {
    e.preventDefault();
    if (formData.subject === "") {
      notification("error", t("Please enter the subject"));
      return false;
    }
    if (formData.message === "") {
      notification("error", t("Please enter the message"));
      return false;
    }

    const payload = {
      subject: formData.subject,
      message: formData.message,
      user_id: user.id,
      user_name: user.name,
      file: uploadedFile,
    };

    const response = await ApiService.userNeedHelp(payload);

    if (response.status) {
      window.location.reload();
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <div className="edit-profile-wrapper">
        <h5 className="header-text">{t("Need Help?")}</h5>
        <form onSubmit={handleSubmitFormData}>
          <div className="row">
            <div className="col-lg-6">
              <div className="input-bottom">
                <label className="label-text mb-1">{t("Subject")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Type here")}
                  name="subject"
                  onChange={(e) => {
                    HandleChangeUpdateForm("subject", e.target.value);
                  }}
                />
              </div>
              <div className="input-bottom">
                <label className="label-text mb-1">{t("Your Message")}</label>
                <textarea
                  type="text"
                  className="form-control"
                  rows="4"
                  name="message"
                  placeholder={t("Type your message")}
                  onChange={(e) => {
                    HandleChangeUpdateForm("message", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-lg-12">
                <label className="label-text mb-1 mt-2">{t("Upload File")}</label>
                <div className="upload-photo mt-2">
                  <FileUploader setUploadedFile={setUploadedFile} />
                </div>
                {uploadedFile && (
                  <div className="text-center mt-1">
                    <img src={uploadedFile} height="150" width="150" alt={t("Uploaded File")} />
                  </div>
                )}
              </div>
              <div className="col-md-6 input-bottom mt-5 ms-auto">
                <button type="submit" className="notification-load-more">
                  {t("Send Message")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
