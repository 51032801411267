import { useTranslation } from "react-i18next";
import TopTemples from "../common/topTemples";
import DrillChart from "../common/drillChart";
import ChartEvents from "../common/chartEvents";
import IndiaMap from "../common/indiaMap";
import StackedChart from "../common/stackedChart";

const GBCDashboard = ({ selectedEvent, topTemplesList, user }) => {
  const { t, i18n } = useTranslation();

  // Zones
  const zonesList = ["East Zone", "West Zone", "South Zone", "North Zone"];

  return (
    <>
      <div className="row mt-4">
        <div className="col-md-8">
          <TopTemples topTemplesList={topTemplesList} isGBCUser={true} user={user} />
        </div>
        <div className="col-md-4">
          <DrillChart selectedEvent={selectedEvent} />
        </div>
      </div>

      <div className="mt-4">
        <h2 className="welcome-text mb-4 graph-stats-div graphs-heading">{t("Books Distributed by Category in each Zone")}</h2>
        <div className="row">
          {zonesList.map((zone, i) => (
            <div className="col-md-3" key={i}>
              <DrillChart zone={zone} selectedEvent={selectedEvent} />
            </div>
          ))}
        </div>
      </div>

      <div className="row mt-4">
        <IndiaMap selectedEvent={selectedEvent} />
        <div className="col-md-6">
          <StackedChart type={"temples"} selectedEvent={selectedEvent} />

          <div className="mt-3">
            {selectedEvent == "Regular Update" || selectedEvent == "Gita Marathon" ? (
              <StackedChart type={"books"} selectedEvent={selectedEvent} />
            ) : (
              <ChartEvents type={"books"} selectedEvent={selectedEvent} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GBCDashboard;
