import React, { useState } from "react";
import "../../css/commonCss.css";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";

function TopTemples({ topTemplesList, user, isGBCUser = false, selectedEvent = "", templeCategory = "" }) {
  const { t, i18n } = useTranslation();
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const colorsList = {
    0: "#72201E",
    1: "#87322C",
    2: "#7E304C",
    3: "#983D54",
    4: "#683964",
    5: "#9F5180",
    6: "#9B69AA",
    7: "#9083CC",
    8: "#849CE3",
    9: "#607FC4",
  };

  const maxY = Math.max(...topTemplesList.map((item) => item.y));

  const updatedData = topTemplesList.map((item, i) => ({
    ...item,
    percentage: (item.y / maxY) * 100,
    color: colorsList[i],
  }));

  const handleDownload = () => {
    setIsButtonVisible(false);

    setTimeout(() => {
      html2canvas(document.querySelector("#top-temples")).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "top-temples-graph.png";
        link.click();
      }, 1000);

      setTimeout(() => {
        setIsButtonVisible(true);
      }, 1000);
    });
  };

  return (
    <div
      className={
        "top-temples-card " +
        (!isGBCUser ? (selectedEvent == "Regular Update" || selectedEvent == "Gita Marathon" ? "" : "rsa-temple-performance-card") : "")
      }
      id="top-temples"
    >
      <div className="head-sec">
        <div className="temple-list-head-sec">
          <h2 className="pledge-head-temple">
            {i18n.language != "hi" ? (
              <>
                {"Top 10 "} {!isGBCUser ? user.temple + "s In India" : templeCategory != "" ? templeCategory + " In India" : "" + t("Temples") + " In India"}
              </>
            ) : (
              <>
                {"भारत में टॉप 10 "} {!isGBCUser ? t(user.temple) : t("Temples")}
              </>
            )}
          </h2>
        </div>
        {isButtonVisible && (
          <span className="button-text-download" onClick={handleDownload}>
            {t("Download")}
          </span>
        )}
      </div>
      <div className="mt-2">
        {updatedData.map((row, index) => (
          <div
            className={"temple-progress-div row " + (user.role != "GBC (Governing Body Commission)" ? "user-temple-progress" : "gbc-temple-progress")}
            key={index}
          >
            <div className="top-name-div">
              <span className="temple-name">{i18n.language === "hi" ? row?.hindilabel : row.label}</span>
            </div>
            <div className="top-progress-div">
              <div className="temple-progress-bar-container">
                <div className="progress-bar" style={{ width: `${row.percentage}%`, backgroundColor: row.color }} />
              </div>
            </div>
            <div className="top-count-div">
              <span className="temple-points-count">{parseFloat(row.y?.toFixed(2)).toLocaleString("en-IN")}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TopTemples;
