import { useEffect, useState } from "react";
import ApiService from "../../api/ApiService";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";

export default function StateWisePointsTable({ selectedEvent }) {
  const allStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Chandigarh",
    "Delhi",
    "Puducherry",
    "Lakshadweep",
  ];
  const [statePointsData, getStatePointsData] = useState([]);
  const { t, i18n } = useTranslation();

  const getStateWisePointsForMap = async () => {
    getStatePointsData([]);
    try {
      const res = await ApiService.getStateWisePointsForMap(selectedEvent);

      if (res.data.length > 0) {
        const apiDataMap = res.data.reduce((acc, { state_name, value }) => {
          acc[state_name.toLowerCase()] = value;
          return acc;
        }, {});

        const finalData = allStates.map((state) => ({
          state_name: state,
          value: apiDataMap[state.toLowerCase()] || 0,
        }));

        finalData.sort((a, b) => Number(b.value) - Number(a.value));

        getStatePointsData(finalData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStateWisePointsForMap();
  }, [selectedEvent]);

  return (
    <>
      {statePointsData.length > 0 && (
        <div className="col-md-6">
          <div className="user-welcome-card">
            <h2 className="welcome-text graphs-heading">{t("State Wise Rank")}</h2>
            <div className="history-table mt-3" style={{ maxHeight: "900px" }}>
              <Table responsive className="table history-custom-table">
                <thead>
                  <tr className="text-start sticky-header">
                    <th className="text-start table-row-header" style={{ paddingLeft: "20px", paddingRight: "70px" }}>
                      {t("Rank")}
                    </th>
                    <th className="table-row-header text-start transaction-cell">{t("State Name")}</th>
                    <th className="text-end table-row-header" style={{ paddingRight: "20px" }}>
                      {t("Points")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {statePointsData.map((item, index) => (
                    <tr key={index}>
                      <td className="text-start" style={{ paddingLeft: "20px", paddingRight: "70px" }}>
                        {index + 1}
                      </td>

                      <td className="history-cell-text">{t(item.state_name)}</td>
                      <td className="text-end history-cell-text" style={{ paddingRight: "20px" }}>
                        {Math.ceil(item?.value)?.toLocaleString("en-IN")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
