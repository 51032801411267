import { useContext, useEffect, useState } from "react";
import "../../css/RecordingOfScores.css";
import "../../css/app.css";
import ApiService from "../../api/ApiService";
import AppContext from "../../context/AppContext";
import html2canvas from "html2canvas";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function BooksPledgeTable({ selectedEvent, isRSAUser = false, isGBC = false, templeId = "" }) {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const progressTextMapping = {
    ChaitanyaCharitamritaSet: "9 Mahabig Books",
    SrimadBhagavatamSet: "18 Mahabig Books",
    MahabigBooks: "500+ Pages",
    BigBooks: "300-500 Pages",
    MediumBooks: "150-300 Pages",
    SmallBooks: "100-150 Pages",
    Magazines: "10-50 Pages",
    "1 Year BTG Subscription-1": "English Book",
    "1 Year BTG Subscription-2": "Non-English Book",
  };

  const progressImagesMapping = {
    ChaitanyaCharitamritaSet: "/images/books-images/cc-sets.png",
    SrimadBhagavatamSet: "/images/books-images/sb-sets.webp",
    MahabigBooks: "/images/books-images/maha-big-book.jpg",
    BigBooks: "/images/books-images/big-books.jpg",
    MediumBooks: "/images/books-images/medium-books.jpg",
    SmallBooks: "/images/books-images/small-books.jpg",
    Magazines: "/images/books-images/magazine.png",
    "1 Year BTG Subscription-1": "/images/books-images/btg.jpg",
    "1 Year BTG Subscription-2": "/images/books-images/btg.jpg",
  };

  const booksObj = {
    ChaitanyaCharitamritaSet: 0,
    SrimadBhagavatamSet: 0,
    MahabigBooks: 0,
    BigBooks: 0,
    MediumBooks: 0,
    SmallBooks: 0,
    Magazines: 0,
    "1 Year BTG Subscription-1": 0,
    "1 Year BTG Subscription-2": 0,
  };

  const { user } = useContext(AppContext);

  const bookTypes = Object.keys(progressTextMapping);
  const [pledgeCountsFromDB, setPledgeCountFromDB] = useState([]);
  const [submittedScoreFromDB, setSubmittedScoreFromDB] = useState([]);
  const [booksCounts, setBooksCounts] = useState(booksObj);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  useEffect(() => {
    fetchSubmittedScoreCount(user, selectedEvent);
    fetchPledgeCount(user, selectedEvent);
  }, [user, selectedEvent, templeId]);

  const calculateBookTypeCount = (bookType) => {
    let totalCount = 0;
    submittedScoreFromDB.forEach((item) => {
      if (item.book_id === bookType) {
        totalCount += parseInt(item.count_book, 10);
      }
    });

    return totalCount;
  };

  const fetchPledgeCount = async (user) => {
    try {
      let res = [];
      if (isGBC) {
        res = await ApiService.getAllTemplesPledgeData(selectedEvent);
      } else {
        if (!isRSAUser) {
          res = await ApiService.fetchPledgeCount(templeId != "" ? templeId : user.temple_id, selectedEvent, true);
        } else {
          const postData = {
            temple_array: user.temple_array,
            state_array: user.state_array,
            zone_array: user.zone_array,
            event: selectedEvent,
          };

          res = await ApiService.getRSAPledgeData(postData);
        }
      }
      console.log("res12334", res);
      setPledgeCountFromDB(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubmittedScoreCount = async (user) => {
    setSubmittedScoreFromDB([]);
    setBooksCounts(booksObj);

    const resultCount = {};
    const resultPoints = {};

    let res = [];
    try {
      if (isGBC) {
        res = await ApiService.getAllTemplesScore(selectedEvent);
      } else {
        if (!isRSAUser) {
          if (selectedEvent == "Regular Update") {
            res = await ApiService.fetchSubmittedScoreRegularEvent(templeId != "" ? templeId : user.temple_id, selectedEvent, true);
          } else {
            res = await ApiService.fetchSubmittedScoreCount(templeId != "" ? templeId : user.temple_id, selectedEvent, "date", true);
          }
        } else {
          const postData = {
            temple_array: user.temple_array,
            state_array: user.state_array,
            zone_array: user.zone_array,
            event: selectedEvent,
          };

          res = await ApiService.getRSAScoreData(postData);
        }
      }
      if (res.length > 0) {
        res.forEach((item) => {
          resultCount[item.book_id] = item.count_book;
          resultPoints[item.book_id] = item.total_points;
        });
        setBooksCounts((prevState) => ({
          ...prevState,
          ...resultCount,
        }));

        const accumulatedData = res.reduce((acc, item) => {
          const key = `${item.book_id}-${item.event}`;

          if (!acc[key]) {
            acc[key] = {
              ...item,
              count_book: item.count_book,
              total_points: item.total_points,
            };
          } else {
            acc[key].count_book += item.count_book;
            acc[key].total_points += item.total_points;
          }

          return acc;
        }, {});

        const resultArray = Object.values(accumulatedData);
        setSubmittedScoreFromDB(resultArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let finalCount = 0;

  const handleDownload = () => {
    setIsButtonVisible(false);

    setTimeout(() => {
      html2canvas(document.querySelector(".pledge-cover-table")).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "pledge-cover-table.png";
        link.click();
      });
    }, 1000);

    setTimeout(() => {
      setIsButtonVisible(true);
    }, 1000);
  };

  return (
    <div className="pledge-cover-table">
      <div className="pledge-cover-card">
        <div className="head-sec-pledge-table">
          <div className="temple-list-head-sec">
            <h2 className="pledge-head-temple">{t("Pledge Covered")}</h2>
          </div>
          {isButtonVisible && (
            <a className="button-text-download" onClick={handleDownload}>
              {t("Download")}
            </a>
          )}
        </div>
        <table className="table">
          <thead className="table-head">
            <tr>
              <th scope="col text-start">{t("Book")}</th>
              <th scope="col text-start">{t("Progress")}</th>
              <th scope="col" className="text-end">
                {t("Books Count")}
              </th>
              <th scope="col text-start">{t("Status")}</th>
            </tr>
          </thead>

          <tbody>
            {bookTypes.map((bookType) => {
              const shouldDisplayRow =
                selectedEvent === "Select Event" ||
                selectedEvent === "Regular Update" ||
                (selectedEvent === "Bhadra Purnima" && bookType === "SrimadBhagavatamSet") ||
                selectedEvent === "Gita Marathon" ||
                (selectedEvent === "Chaitanya Charitamrita" && bookType === "ChaitanyaCharitamritaSet");

              const count = calculateBookTypeCount(bookType);
              finalCount = Number(finalCount) + Number(count);
              const progressText = progressTextMapping[bookType] || "Progress text";
              if (shouldDisplayRow) {
                return (
                  <tr key={bookType}>
                    <td scope="row text-start">
                      <div className="d-flex align-items-center">
                        <div className="pledge-image-p-1">
                          <img src={progressImagesMapping[bookType]} className="rounded" width="45" height="45" alt={bookType} />
                        </div>
                        <div className="text-start">
                          <h4 className="m-0 font-weight-bold">
                            {!bookType?.includes("BTG") ? t(bookType.replace(/([A-Z])/g, " $1").trim()) : t(bookType)}
                          </h4>
                          <p className="m-0 pages-count">{t(progressText)}</p>
                        </div>
                      </div>
                    </td>

                    <td className="text-start">
                      {(() => {
                        const pledgePoints = pledgeCountsFromDB.find((item) => item.book_name === bookType)?.count_book || 0;
                        let submittedScore = [];

                        submittedScore = submittedScoreFromDB.find((item) => item.book_id === bookType);

                        const submittedCount = submittedScore ? parseInt(submittedScore.count_book, 10) : 0;

                        const progressPercentage = pledgePoints > 0 ? Math.floor((submittedCount / pledgePoints) * 100) : 0;

                        let progressColor;
                        if (progressPercentage <= 20) {
                          progressColor = "#EB3D4D";
                        } else if (progressPercentage > 20 && progressPercentage <= 80) {
                          progressColor = "#F86624";
                        } else {
                          progressColor = "#F86624";
                          if (progressPercentage >= 100) {
                            progressColor = "#22CAAD";
                          }
                        }

                        // Return the JSX for the progress bar
                        return (
                          <div className="progress-bar-container p-2">
                            <div className="col-sm-12">
                              <div className="row mt-2 progress-div">
                                <div className="col-lg-9 p-1">
                                  <div className="progress">
                                    <div
                                      className="progress-bar rounded"
                                      role="progressbar"
                                      style={{
                                        height: "100%",
                                        width: `${progressPercentage}%`,
                                        backgroundColor: progressColor,
                                      }}
                                      aria-valuenow={progressPercentage}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-2 p-0">
                                  <span className="progress-percentage" style={{ color: progressColor, fontSize: "13px", fontWeight: "900" }}>
                                    {progressPercentage.toFixed(0)}%
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })()}
                    </td>

                    <td className="text-end books-count-padding-top">{Math.ceil(count)?.toLocaleString("en-IN")}</td>
                    <td className="text-start books-status-padding-top">
                      {(() => {
                        const pledgePoints = pledgeCountsFromDB.find((item) => item.book_name === bookType)?.count_book || 0;
                        let submittedScore = [];

                        submittedScore = submittedScoreFromDB.find((item) => item.book_id === bookType);

                        const submittedCount = submittedScore ? parseInt(submittedScore.count_book, 10) : 0;

                        const progressPercentage = pledgePoints > 0 ? (submittedCount / pledgePoints) * 100 : 0;

                        let progressColor;
                        let progressText;
                        let borderColor;

                        if (progressPercentage <= 20) {
                          progressColor = "#EB3D4D";
                          progressText = "Unfinished";
                          borderColor = "#FEECEE";
                        } else if (progressPercentage > 20 && progressPercentage <= 80) {
                          progressColor = "#F86624";
                          progressText = "In Progress";
                          borderColor = "#FFF0EA";
                        } else {
                          progressColor = "#F86624";
                          if (progressPercentage >= 100) {
                            progressColor = "#22CAAD";
                            progressText = "Finished";
                            borderColor = "#E9FAF7";
                          } else {
                            progressText = "In Progress";
                            borderColor = "#FFF0EA";
                          }
                        }

                        // Return the JSX for the progress bar
                        return (
                          <div className="progress-bar-container mt-1">
                            <div
                              style={{
                                padding: "4px 10px 4px 10px",
                                // gap: "8px",
                                borderRadius: "8px",
                                backgroundColor: borderColor,
                                textAlign: "center",
                              }}
                            >
                              <span className="progress-percentage" style={{ color: progressColor, fontSize: "13px", fontWeight: "900" }}>
                                {t(progressText)}
                              </span>
                            </div>
                          </div>
                        );
                      })()}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>

          {/* <tfoot>
        <tr>
          <th scope="row">Total Count</th>
          <td></td>
          <td></td>
          <td>{finalCount}</td>
        </tr>
      </tfoot> */}
        </table>
      </div>
    </div>
  );
}
