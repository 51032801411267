import { useContext, useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import DataRangePicker from "../components/common/DateRangeCalendar";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { VscThreeBars } from "react-icons/vsc";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { FiExternalLink } from "react-icons/fi";
import { FaFacebookF, FaYoutube, FaInstagram, FaMobileAlt } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { HiUser } from "react-icons/hi";
import { Modal } from "react-bootstrap";
import AppContext from "../context/AppContext";
import ApiService from "../api/ApiService";
import notification from "../helpers/notification";
import { RiLiveFill } from "react-icons/ri";
import axios from "axios";
import MetaData from "../helpers/MetaData";
import "../css/RecordingOfScores.css";
// import "./RecordingOfScores.css";
import "../css/app.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment/moment";
import Modale from "react-modal";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import { Upload, X } from 'lucide-react';


Modale.setAppElement("#root");
// Define points per book type
const POINTS_PER_BOOK = {
  ChaitanyaCharitamritaSet: 36.0,
  SrimadBhagavatamSet: 72.0,
  MahabigBooks: 2.0,
  BigBooks: 1.0,
  MediumBooks: 0.5,
  SmallBooks: 0.25,
  Magazines: 0.1,
  "1 Year BTG Subscription-1": 5.0,
  "1 Year BTG Subscription-2": 2.5,
};

const progressTextMapping = {
  ChaitanyaCharitamritaSet: "9 Mahabig Books",
  SrimadBhagavatamSet: "18 Mahabig Books",
  MahabigBooks: "500+ Pages",
  BigBooks: "300-500 Pages",
  MediumBooks: "150-300 Pages",
  SmallBooks: "100-150 Pages",
  Magazines: "10-50 Pages",
  "1 Year BTG Subscription-1": "English Book",
  "1 Year BTG Subscription-2": "Non-English Book",
};

const progressImagesMapping = {
  ChaitanyaCharitamritaSet: "/images/books-images/cc-sets.png",
  SrimadBhagavatamSet: "/images/books-images/sb-sets.webp",
  MahabigBooks: "/images/books-images/maha-big-book.jpg",
  BigBooks: "/images/books-images/big-books.jpg",
  MediumBooks: "/images/books-images/medium-books.jpg",
  SmallBooks: "/images/books-images/small-books.jpg",
  Magazines: "/images/books-images/magazine.png",
  "1 Year BTG Subscription-1": "/images/books-images/btg.jpg",
  "1 Year BTG Subscription-2": "/images/books-images/btg.jpg",
};

const RecordingOfScores = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const bookTypes = Object.keys(progressTextMapping);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const [counts, setCounts] = useState({
    ChaitanyaCharitamritaSet: 0,
    SrimadBhagavatamSet: 0,
    MahabigBooks: 0,
    BigBooks: 0,
    MediumBooks: 0,
    SmallBooks: 0,
    Magazines: 0,
    "1 Year BTG Subscription-1": 0,
    "1 Year BTG Subscription-2": 0,
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      state: "",
      city: "",
    },
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };


  // const handleFileSelect = (e) => {
  //   setFiles([...files, ...Array.from(e.target.files)]);
  //   handleFileChange(e); // Maintain existing file handling
  // };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };


  const [points, setPoints] = useState({
    ChaitanyaCharitamritaSet: 0,
    SrimadBhagavatamSet: 0,
    MahabigBooks: 0,
    BigBooks: 0,
    MediumBooks: 0,
    SmallBooks: 0,
    Magazines: 0,
    "1 Year BTG Subscription-1": 0,
    "1 Year BTG Subscription-2": 0,
  });

  const [selectedEvent, setSelectedEvent] = useState("Regular Update");
  const [pledgeCountsFromDB, setPledgeCountFromDB] = useState([]);
  const [submittedScoreFromDB, setSubmittedScoreFromDB] = useState([]);
  const [userScoreData, setUserScoreData] = useState({});
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalPopUPText, setModalPopUPText] = useState("");
  const [isContUpdated, setIsContUpdated] = useState(false);
  const [regularModify, setRegularModify] = useState(false);
  const [regularEventsCounts, setRegularEventsCounts] = useState([]);
  const [overallSubmittedScore, setOverallSubmittedScore] = useState([]);
  // const [files, setFiles] = useState([]);

  // const handleFileChange = (e) => {
  //   setFiles([...files, ...Array.from(e.target.files)]);
  // };

  // const removeFile = (index) => {
  //   setFiles(files.filter((_, i) => i !== index));
  // };

  const navigate = useNavigate();

  const { user, setHistoryEvent } = useContext(AppContext);

  const totalPoints = Object.values(points).reduce((sum, point) => {
    if (point != 0) {
      return sum + point;
    } else {
      return sum;
    }
  }, 0);

  const totalCounts = Object.values(counts).reduce((sum, count) => {
    if (count != 0) {
      return sum + count;
    } else {
      return sum;
    }
  }, 0);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  // Upload Excel Files Start
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [files, setFiles] = useState([]);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);

      console.log("File selected:", file.name);
    } else {
      console.log("No file selected");
    }
  };

  // const handleUploadClick = async () => {
  //   if (!selectedFile) {
  //     console.log("No file selected for upload");
  //     return;
  //   }

  //   const reader = new FileReader();

  //   reader.onload = async (event) => {
  //     const data = new Uint8Array(event.target.result);
  //     const workbook = XLSX.read(data, { type: "array" });

  //     const sheetName = workbook.SheetNames[0];
  //     const worksheet = workbook.Sheets[sheetName];
  //     const jsonData = XLSX.utils.sheet_to_json(worksheet);

  //     const userData = {
  //       id: user.id,
  //       name: user.name,
  //       hindiname: user.hindiname,
  //       phone: user.phone,
  //       email: user.email,
  //       zone: user.zone,
  //       temple: user.temple,
  //       state: user.state,
  //       status: user.status,
  //       role: user.role,
  //       temple_id: user.temple_id,
  //       temple_name: user.temple_name,
  //       hinditempleName: user.hinditempleName,
  //       state_id: user.state_id,
  //       state_name: user.state_name,
  //       zone_name: user.zone_name,
  //       zone_code: user.zone_code,
  //       event: selectedEvent,
  //     };

  //     const updatedData = jsonData.map((item) => {
  //       const pointsPerBook = POINTS_PER_BOOK[item["Book Name"]] || 0;
  //       const total_points = pointsPerBook * (item["Book Count"] || 0);
  //       return {
  //         ...item,
  //         total_points,
  //         ...userData,
  //       };
  //     });
  //     console.log("updatedData", updatedData);

  //     try {
  //       await ApiService.uploadBulkScore(updatedData);
  //       setExcelData(updatedData);

  //       notification("success", "Score Submitted Successfully");
  //       setTimeout(() => {
  //         setSelectedFile(null);
  //       }, 1000);

  //       closeModal();
  //     } catch (error) {
  //       console.error("Error uploading score:", error);
  //       notification("error", "Failed to Submit Score");
  //     }
  //   };

  //   reader.readAsArrayBuffer(selectedFile);
  // };


  const handleUploadClick = async () => {
    if (!selectedFile) {
      console.log("No file selected for upload");
      return;
    }

    const reader = new FileReader();

    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Utility function to convert Excel date serial to "DD-MM-YYYY" format
      const formatExcelDate = (excelDate) => {
        if (typeof excelDate === "number") {
          const date = new Date((excelDate - 25569) * 86400 * 1000); // Convert Excel serial number to JS date
          const day = String(date.getUTCDate()).padStart(2, '0');
          const month = String(date.getUTCMonth() + 1).padStart(2, '0');
          const year = date.getUTCFullYear();
          return `${day}-${month}-${year}`;
        }
        return excelDate; // Return as is if it's already a string in the correct format
      };

      // Map through data and format the date correctly
      const formattedData = jsonData.map((item) => {
        return {
          ...item,
          "Date Of Distribution": formatExcelDate(item["Date Of Distribution"]), // Ensure date is in DD-MM-YYYY
        };
      });

      // Define the allowed book names based on the selectedEvent
      let allowedBooks = [];

      if (selectedEvent === "Bhadra Purnima") {
        allowedBooks = ["SrimadBhagavatamSet"];
      } else if (selectedEvent === "Chaitanya Charitamrita") {
        allowedBooks = ["ChaitanyaCharitamritaSet"];
      } else {
        // Accept all books for any other event
        allowedBooks = formattedData.map((item) => item["Book Name"]);
      }

      // Filter the formattedData to only include allowed books
      const filteredData = formattedData.filter((item) =>
        allowedBooks.includes(item["Book Name"])
      );

      if (filteredData.length === 0) {
        notification(
          "error",
          `No valid books found for the event: ${selectedEvent}`
        );
        return;
      }

      const userData = {
        id: user.id,
        name: user.name,
        hindiname: user.hindiname,
        phone: user.phone,
        email: user.email,
        zone: user.zone,
        temple: user.temple,
        state: user.state,
        status: user.status,
        role: user.role,
        temple_id: user.temple_id,
        temple_name: user.temple_name,
        hinditempleName: user.hinditempleName,
        state_id: user.state_id,
        state_name: user.state_name,
        zone_name: user.zone_name,
        zone_code: user.zone_code,
        event: selectedEvent,
      };

      const updatedData = filteredData.map((item) => {
        const pointsPerBook = POINTS_PER_BOOK[item["Book Name"]] || 0;
        const total_points = pointsPerBook * (item["Book Count"] || 0);
        return {
          ...item,
          total_points,
          ...userData,
        };
      });

      console.log("updatedData", updatedData);

      try {
        await ApiService.uploadBulkScore(updatedData);
        setExcelData(updatedData);

        notification("success", "Score Submitted Successfully");
        setTimeout(() => {
          setSelectedFile(null);
        }, 1000);

        closeModal();
      } catch (error) {
        console.error("Error uploading score:", error);
        notification("error", "Failed to Submit Score");
      }
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  const downloadSampleExcel = () => {
    // Define the full sample data
    const fullSampleData = [
      {
        "Book Name": "ChaitanyaCharitamritaSet",
        "Date Of Distribution": "12-08-2024",
        "Book Count": 100,
      },
      {
        "Book Name": "MahabigBooks",
        "Date Of Distribution": "13-08-2024",
        "Book Count": 200,
      },
      {
        "Book Name": "SrimadBhagavatamSet",
        "Date Of Distribution": "14-08-2024",
        "Book Count": 300,
      },
      {
        "Book Name": "MediumBooks",
        "Date Of Distribution": "15-08-2024",
        "Book Count": 400,
      },
      {
        "Book Name": "BigBooks",
        "Date Of Distribution": "16-08-2024",
        "Book Count": 500,
      },
      {
        "Book Name": "1 Year BTG Subscription-1",
        "Date Of Distribution": "16-08-2024",
        "Book Count": 600,
      },
      {
        "Book Name": "1 Year BTG Subscription-2",
        "Date Of Distribution": "16-08-2024",
        "Book Count": 700,
      },
      {
        "Book Name": "SmallBooks",
        "Date Of Distribution": "13-08-2024",
        "Book Count": 800,
      },
      {
        "Book Name": "Magazines",
        "Date Of Distribution": "13-08-2024",
        "Book Count": 900,
      },
    ];

    // Filter the data based on the selectedEvent
    let filteredData = fullSampleData; // Default is all data

    if (selectedEvent === "Bhadra Purnima") {
      filteredData = fullSampleData.filter(book => book["Book Name"] === "SrimadBhagavatamSet");
    } else if (selectedEvent === "Chaitanya Charitamrita") {
      filteredData = fullSampleData.filter(book => book["Book Name"] === "ChaitanyaCharitamritaSet");
    }

    // Create Excel file with filtered data
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sample");

    // Download the file
    XLSX.writeFile(workbook, "SampleUpload.xlsx");
  };


  // Upload Excel Files End

  const fetchPledgeCount = async (user) => {
    try {
      const res = await ApiService.fetchPledgeCount(user.temple_id, selectedEvent, true);

      setPledgeCountFromDB(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubmittedScoreCountByUserID = async (user) => {
    setCounts({
      ChaitanyaCharitamritaSet: 0,
      SrimadBhagavatamSet: 0,
      MahabigBooks: 0,
      BigBooks: 0,
      MediumBooks: 0,
      SmallBooks: 0,
      Magazines: 0,
      "1 Year BTG Subscription-1": 0,
      "1 Year BTG Subscription-2": 0,
    });
    setPoints({
      ChaitanyaCharitamritaSet: 0,
      SrimadBhagavatamSet: 0,
      MahabigBooks: 0,
      BigBooks: 0,
      MediumBooks: 0,
      SmallBooks: 0,
      Magazines: 0,
      "1 Year BTG Subscription-1": 0,
      "1 Year BTG Subscription-2": 0,
    });

    const resultCount = {};
    const resultPoints = {};

    setSubmittedScoreFromDB([])

    if((selectedEvent == "Regular Update" && selectedDate !=null)||selectedEvent != "Regular Update"){
    try {
      const res = await ApiService.fetchSubmittedScoreCount(user.id, selectedEvent, selectedEvent == "Regular Update" ? selectedDate : "date", false);
      if (res.length > 0) {
        res.forEach((item) => {
          resultCount[item.book_id] = item.count_book;
          resultPoints[item.book_id] = item.total_points;
        });
        setCounts((prevState) => ({
          ...prevState,
          ...resultCount,
        }));

        setPoints((prevState) => ({
          ...prevState,
          ...resultPoints,
        }));
      }
      setSubmittedScoreFromDB(res);
    } catch (error) {
      console.log(error);
    }
   }
  };

  useEffect(() => {
    fetchPledgeCount(user);
    fetchOverAllSubmittedScoreCountByTempleID(user);
    fetchDataFromAllLiveRankingByUserId(user);
  }, [user, selectedEvent]);

  useEffect(() => {
    fetchSubmittedScoreCountByUserID(user);
  }, [user, selectedEvent, selectedDate]);

  useEffect(() => {
    const calculatePoints = (bookType) => {
      const count = counts[bookType];
      const pointPerBook = POINTS_PER_BOOK[bookType];
      setPoints((prevPoints) => ({
        ...prevPoints,
        [bookType]: count * pointPerBook,
      }));
    };

    Object.keys(counts).forEach(calculatePoints);
  }, [counts]);

  const updateCount = (bookType, value) => {
    const toastifyDiv = document.getElementsByClassName("Toastify__toast-container");
    if (toastifyDiv.length > 0) {
      return false;
    }

    if (selectedEvent == "Select Event") {
      notification("warning", "Please select an event to add book count.");
      return false;
    }

    if (selectedEvent == "Regular Update" && !regularModify && submittedScoreFromDB.length > 0) {
      notification("warning", "Please click on modify to update the book count.");
      return false;
    }

    if (selectedEvent == "Regular Update" && selectedDate == null) {
      notification("warning", "Please select a date to add book count.");
      return false;
    }

    setIsContUpdated(true);
    setCounts((prevCounts) => ({
      ...prevCounts,
      [bookType]: value < 0 ? 0 : value,
    }));
  };

  const calculateTotalBookCounts = () => {
    const bookCounts = {};

    // Iterate over the pledgeCountsFromDB data
    pledgeCountsFromDB.forEach((item) => {
      const bookName = item.book_name;
      const countBook = parseInt(item.count_book, 10); // Convert count_book to integer

      // If the bookName is already in the dictionary, add the countBook to the existing count
      if (bookName in bookCounts) {
        bookCounts[bookName] += countBook;
      } else {
        // Otherwise, initialize the count
        bookCounts[bookName] = countBook;
      }
    });

    return bookCounts;
  };
  // const bookTypes = ['Magazines', 'SmallBooks', 'Medium Books', 'Big Books', 'Mahabig Books', '1 Year BTG'];
  const calculateBookTypeCount = (bookType) => {
    let totalCount = 0;

    // Filter pledgeCountsFromDB data for the specified book type and sum the count_book values
    pledgeCountsFromDB.forEach((item) => {
      if (item.book_name === bookType) {
        totalCount += parseInt(item.count_book, 10); // Convert count_book to integer
      }
    });

    return totalCount;
  };

  // Calculate the total counts once the data is loaded
  const bookCounts = calculateTotalBookCounts();

  const previousEvent = submittedScoreFromDB.map((Previous) => Previous.event);
  const PreviousBook = submittedScoreFromDB.map((Previous) => Previous.book_id);

  let modalText = "";

  const previousEventBookCounts = submittedScoreFromDB.reduce((acc, curr) => {
    if (!acc[curr.event]) {
      acc[curr.event] = {};
    }
    acc[curr.event][curr.book_id] = curr.count_book;
    return acc;
  }, {});

  const handleScoreSave = async () => {
    const toastifyDiv = document.getElementsByClassName("Toastify__toast-container");
    if (toastifyDiv.length > 0) {
      return false;
    }
    if (Object.values(counts).some((item) => item > 0)) {
      const currentDate = moment().format("YYYY-MM-DD");

      let showPopUp = false;

      const data = {
        scores: Object.keys(counts)
          .filter(
            (bookType) =>
              counts[bookType] > 0 &&
              document.getElementsByClassName(bookType).length > 0 &&
              (typeof previousEventBookCounts[selectedEvent] == "undefined" ||
                document.getElementsByClassName(bookType)[0].value != previousEventBookCounts[selectedEvent][bookType])
          )
          .map((bookType) => {
            if (previousEvent.includes(selectedEvent) && PreviousBook.includes(bookType)) {
              if (counts[bookType] != previousEventBookCounts[selectedEvent][bookType] || isContUpdated) {
                showPopUp = true;
                return {
                  book: bookType,
                  count_book: counts[bookType],
                  total_points: points[bookType],
                  book_id: bookType,
                  user_id: user.id,
                  selectedStartDate: selectedEvent == "Regular Update" ? selectedDate : currentDate,
                  selectedEndDate: endDate,
                  user_name: user.name,
                  hindiname: user.hindiname,
                  temple_id: user.temple_id,
                  temple_name: user.temple_name,
                  temple: user.temple,
                  state_id: user.state_id,
                  state_name: user.state_name,
                  zone: user.zone_name,
                  event: selectedEvent,
                  hinditempleName: user.hinditempleName,
                };
              } else {
                showPopUp = true;
                modalText = "The entered book count matches the previous count. Please update the count to proceed further.";
                return null;
              }
            } else {
              return {
                book: bookType,
                count_book: counts[bookType],
                total_points: points[bookType],
                book_id: bookType,
                user_id: user.id,
                selectedStartDate: selectedEvent == "Regular Update" ? selectedDate : currentDate,
                selectedEndDate: endDate,
                user_name: user.name,
                hindiname: user.hindiname,
                temple_id: user.temple_id,
                temple_name: user.temple_name,
                temple: user.temple,
                state_id: user.state_id,
                state_name: user.state_name,
                zone: user.zone_name,
                event: selectedEvent,
                hinditempleName: user.hinditempleName,

              };
            }
          })
          .filter((score) => score !== null),
      };

      if (data.scores.length == 0) {
        notification("warning", "Please update the Book Count to proceed further.");
        return false;
      }

      if (showPopUp) {
        setShow(true);
        setModalPopUPText(modalText);
        setModalData(data);
        return false;
      }

      setLoading(true);
      try {
        const res = await ApiService.iccScoreSubmit(data);
        setLoading(false);

        notification("success", "Score Submitted Successfully");
        if (res) {
          setIsContUpdated(false);
          fetchPledgeCount(user);
          fetchSubmittedScoreCountByUserID(user);
          fetchOverAllSubmittedScoreCountByTempleID(user);
          fetchDataFromAllLiveRankingByUserId(user);
          if (selectedEvent == "Regular Update") {
            setRegularModify(false);
          }
        }
      } catch (err) {
        console.log(err);
        notification("error", err.response.data.message);
      }
    } else {
      notification("warning", "Please add the Book Count to proceed further.");
      return false;
    }
  };

  const handleHideModal = () => {
    setShow(false);
  };

  const handleShowPopModal = async () => {
    setLoading(true);
    try {
      const res = await ApiService.iccScoreSubmit(modalData);
      setLoading(false);

      setShow(false);
      notification("success", "Score Submitted Successfully");
      if (res) {
        setModalData([]);
        setIsContUpdated(false);
        fetchPledgeCount(user);
        fetchSubmittedScoreCountByUserID(user);
        fetchOverAllSubmittedScoreCountByTempleID(user);
        fetchDataFromAllLiveRankingByUserId(user);
        if (selectedEvent == "Regular Update") {
          setRegularModify(false);
        }
      }
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }
  };

  useEffect(() => {
    setIsContUpdated(false);
    setRegularModify(false);
  }, [selectedEvent, selectedDate]);

  const fetchDataFromAllLiveRankingByUserId = async (user) => {
    try {
      let res;

      if (user) {
        res = await ApiService.fetchDataFromAllLiveRankingByUserId(user.temple_id, user.zone_name, user.temple, user.state_id, selectedEvent);
        setUserScoreData(res);
      } else {
        throw new Error("User ID is required");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigateTransactions = () => {
    setHistoryEvent(selectedEvent);
    // localStorage.setItem("history-event", selectedEvent);
    setTimeout(() => {
      navigate("/view-history", { state: { page: "update-score" , event: selectedEvent} });
    }, 1000);
  };

  let finalPledgeCount = 0;
  let finalScoreCount = 0;

  const fetchOverAllSubmittedScoreCountByTempleID = async (user) => {
    setOverallSubmittedScore([]);

    let res = [];

    try {
      if (selectedEvent == "Regular Update") {
        res = await ApiService.fetchSubmittedScoreRegularEvent(user.temple_id, selectedEvent, true);
      } else {
        res = await ApiService.fetchSubmittedScoreCount(user.temple_id, selectedEvent, "date", true);
      }

      const accumulatedData = res.reduce((acc, item) => {
        const key = `${item.book_id}-${item.event}`;

        if (!acc[key]) {
          acc[key] = {
            ...item,
            count_book: item.count_book,
            total_points: item.total_points,
          };
        } else {
          acc[key].count_book += item.count_book;
          acc[key].total_points += item.total_points;
        }

        return acc;
      }, {});

      const resultArray = Object.values(accumulatedData);
      
      setOverallSubmittedScore(resultArray);
    } catch (error) {
      console.log(error);
    }
  };

  const calculateOverAllCont = (bookType) => {
    let totalCount = 0;

    overallSubmittedScore.forEach((item) => {
      if (item.book_id == bookType) {
        totalCount += parseInt(Number(item.count_book), 10);
      }
    });
    return totalCount;
  };

  return (
    <>
      <ToastContainer />
      <div className="container common-container pledge-responsive update-responsive">
        <div className="update-score-container">
          <div className="row">
            <div className="col-md-5">
              <div className="card card-shadow">
                <div className="card-body">
                  <h5 className="card-title">{t("Update Your Points")}</h5>
                  <p className="card-text">{t("Submit your book distribution points, update your points and view your points history.")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="card card-shadow">
                <div className="card-body">
                  <h5 className="stats-header">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.4744 17.0183H5.51784C2.96254 17.0183 1.0022 16.0954 1.55903 12.3807L2.20739 7.34633C2.55064 5.49279 3.73294 4.78341 4.77032 4.78341H13.2524C14.305 4.78341 15.4187 5.54618 15.8153 7.34633L16.4637 12.3807C16.9366 15.6759 15.0297 17.0183 12.4744 17.0183Z"
                        stroke="#130F26"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.5889 4.60034C12.5889 2.61194 10.977 1.00003 8.98861 1.00003V1.00003C8.03111 0.995975 7.11143 1.3735 6.43294 2.04913C5.75445 2.72476 5.37304 3.64283 5.37305 4.60034H5.37305"
                        stroke="#130F26"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path d="M11.46 8.3532H11.4219" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M6.60114 8.3532H6.563" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    &nbsp;{t("Your Stats")} {""}
                    {selectedEvent !== "Select Event" && selectedEvent !== "Regular Update" && t("in")+" " + t(selectedEvent)}

                  </h5>

                  <div className="card-text stats">
                    <div className="text-center">
                      <p className="ranking-status-text">{t("All India Rank")}</p>
                      <span className="ranking-count">{userScoreData.all_rank ?? "-"}</span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("State Rank")}</p>
                      <span className="ranking-count">{userScoreData.state_rank ?? "-"}</span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("Zonal Rank")}</p>
                      <span className="ranking-count">{userScoreData.zone_rank ?? "-"}</span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("Category Rank")}</p>
                      <span className="ranking-count">{userScoreData.temple_rank ?? "-"}</span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("Total Points")}</p>
                      <span className="ranking-count">
                        {userScoreData?.total_points ? Math.ceil(userScoreData?.total_points)?.toLocaleString("en-IN") : "-"}
                      </span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("Books Distributed")}</p>
                      <span className="ranking-count">
                        {userScoreData?.count_book ? Math.ceil(userScoreData?.count_book)?.toLocaleString("en-IN") : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6">
              <div className="content-table">
                <div className="d-flex justify-content-between table-description table-padding-bottom">
                  <h3 className="content-title">{t("Your Performance Status")}</h3>
                  <button className="btn-primarysave custom-button" onClick={() => openModal()} style={{ padding: "10px" }}>
                    {t("Bulk Upload")}
                  </button>
                  <button className="btn-primarysave custom-button" onClick={() => handleNavigateTransactions()} style={{ padding: "10px" }}>
                    {t("View Historical Points")}
                  </button>
                  {/* <p className="content-subtitle">with Points</p> */}
                </div>
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th scope="col">{t("Book")}</th>
                      <th scope="col" className="text-left">
                        {t("Progress")}
                      </th>
                      <th scope="col" className="text-left">
                        {t("Pledged")}
                      </th>
                      <th scope="col" className="text-left">
                        {t("Actuals")}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {bookTypes.map((bookType) => {
                      const shouldDisplayRow =
                        selectedEvent === "Select Event" ||
                        selectedEvent === "Regular Update" ||
                        (selectedEvent === "Bhadra Purnima" && bookType === "SrimadBhagavatamSet") ||
                        selectedEvent === "Gita Marathon" ||
                        (selectedEvent === "Chaitanya Charitamrita" && bookType === "ChaitanyaCharitamritaSet");

                      const count = calculateBookTypeCount(bookType);

                      finalPledgeCount = Number(finalPledgeCount) + Number(count);

                      const progressText = progressTextMapping[bookType] || "Progress text";
                      let submittedScoreData = [];

                      if (shouldDisplayRow) {
                        const submittedScoreCount = calculateOverAllCont(bookType);

                        finalScoreCount = Number(finalScoreCount) + Number(submittedScoreCount);

                        return (
                          <tr key={bookType}>
                            <th scope="row" className="cell-50">
                              <div className="d-flex align-items-center">
                                <div className="pledge-image p-2">
                                  <img src={progressImagesMapping[bookType]} className="rounded" width="45" height="45" alt={bookType} />
                                </div>
                                <div className="">
                                  <h4 className="mb-0 font-weight-bold">
                                    {!bookType.includes("BTG") ? t(bookType.replace(/([A-Z])/g, " $1").trim()) : t(bookType)}
                                  </h4>
                                  <p className="ml-2 pages-count">{t(progressText)}</p>
                                </div>
                              </div>
                            </th>

                            <td className="text-left cell-20">
                              {/* Calculate the progress percentage */}
                              {(() => {
                                // Define the total points pledged and scored for this book type
                                const pledgePoints = pledgeCountsFromDB.find((item) => item.book_name == bookType)?.count_book || 0;
                                let submittedScore = [];

                                submittedScore = overallSubmittedScore.find((item) => item.book_id == bookType);

                                const submittedCount = submittedScore ? parseInt(submittedScore.count_book, 10) : 0;

                                const progressPercentage = pledgePoints > 0 ? Math.floor((submittedCount / pledgePoints) * 100) : 0;

                                // Determine the color based on the progress percentage
                                let progressColor;
                                if (progressPercentage <= 20) {
                                  progressColor = "#EB3D4D";
                                } else if (progressPercentage > 20 && progressPercentage <= 80) {
                                  progressColor = "#F86624";
                                } else {
                                  progressColor = "#F86624";
                                  if (progressPercentage >= 100) {
                                    progressColor = "#22CAAD";
                                  }
                                }

                                // Return the JSX for the progress bar
                                return (
                                  <div className="progress-bar-container">
                                    <div className="row mt-3">
                                      <div className="col-7 mt-2 p-0">
                                        <div className="progress">
                                          <div
                                            className="progress-bar rounded"
                                            role="progressbar"
                                            style={{
                                              height: "100%",
                                              width: `${progressPercentage}%`,
                                              backgroundColor: progressColor,
                                            }}
                                            aria-valuenow={progressPercentage}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-3 ml-2 p-0">
                                        &nbsp;&nbsp;
                                        <span className="progress-percentage" style={{ color: progressColor, fontSize: "13px", fontWeight: "900" }}>
                                          {progressPercentage.toFixed(0)}%
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })()}
                            </td>
                            <td className="cell-10">{parseFloat(count.toFixed(2)).toLocaleString("en-IN")}</td>
                            <td className="cell-10">{parseFloat(submittedScoreCount.toFixed(2)).toLocaleString("en-IN")}</td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>

                  <tfoot>
                    <tr>
                      <th scope="row">{t("Total Count")}</th>
                      <td></td>
                      <td className="total-count-text-td">{parseFloat(finalPledgeCount.toFixed(2)).toLocaleString("en-IN")}</td>
                      <td className="total-count-text-td">{parseFloat(finalScoreCount.toFixed(2)).toLocaleString("en-IN")}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div className="col-6">
              <div className="content-table">
                <div className="event-filters">
                  <div className="table-description">
                    <h3 className="content-title">{t("Update Your points")}</h3>
                    <p className="content-subtitle">{t("Edit your points here")}</p>
                  </div>
                  <div className="events-drop-down">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={t(selectedEvent)}
                      onSelect={(eventKey) => setSelectedEvent(eventKey)}
                      className="mr-3 white-dropdown"
                    >
                      <Dropdown.Item value="Regular Update" eventKey="Regular Update">{t("Regular Update")}</Dropdown.Item>
                      <Dropdown.Item value="Bhadra Purnima" eventKey="Bhadra Purnima">{t("Bhadra Purnima Marathon")}</Dropdown.Item>
                      <Dropdown.Item value="Chaitanya Charitamrita" eventKey="Chaitanya Charitamrita">{t("Chaitanya Charitamrita Marathon")}</Dropdown.Item>
                      <Dropdown.Item value="Gita Marathon" eventKey="Gita Marathon">{t("Gita Marathon")}</Dropdown.Item>
                    </DropdownButton>
                  </div>

                  <div className="date-drop-down">
                    {selectedEvent != "Select Event" &&
                      selectedEvent !== "Bhadra Purnima" &&
                      selectedEvent !== "Chaitanya Charitamrita" &&
                      selectedEvent !== "Gita Marathon" && (
                        <DataRangePicker
                          selectedDate={selectedDate}
                          setSelectedDate={setSelectedDate}
                          placeholderText="Select date range"
                          startDate={startDate}
                          endDate={endDate}
                          onStartDateChange={handleStartDateChange}
                          onEndDateChange={handleEndDateChange}
                        />
                      )}
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{t("Book")}</th>
                      <th scope="col" className="text-center">
                        {t("No. Of Books")}
                      </th>
                      <th scope="col" className="text-center">
                        {t("Points")}
                      </th>
                    </tr>
                  </thead>
                  {/*  */}
                  <tbody>
                    {Object.keys(counts).map((bookType) => {
                      const shouldDisplayRow =
                        selectedEvent === "Select Event" ||
                        selectedEvent === "Regular Update" ||
                        (selectedEvent === "Bhadra Purnima" && bookType === "SrimadBhagavatamSet") ||
                        selectedEvent === "Gita Marathon" ||
                        (selectedEvent === "Chaitanya Charitamrita" && bookType === "ChaitanyaCharitamritaSet");

                      if (shouldDisplayRow) {
                        return (
                          <tr key={bookType} className="align-items-center">
                            <th scope="row">
                              <div className="d-flex align-items-center">
                                <div className="pledge-image p-2">
                                  <img
                                    src={progressImagesMapping[bookType]}
                                    // src="https://as2.ftcdn.net/v2/jpg/01/86/36/61/1000_F_186366173_JTAJ2NcdpuEysbfsOUawSYlAg7Pfapvj.jpg"
                                    className="rounded"
                                    width="45"
                                    height="45"
                                    alt={bookType}
                                  />
                                </div>
                                <div className="w-200">
                                  <h4 className="mb-0 font-weight-bold">
                                    {!bookType.includes("BTG") ? t(bookType.replace(/([A-Z])/g, " $1").trim()) : t(bookType)}
                                  </h4>
                                  <p className="ml-2 pages-count">{t(progressTextMapping[bookType] || "Progress text")}</p>
                                </div>
                              </div>
                            </th>
                            <td className="input-box">
                              <button
                                // disabled={selectedEvent == "Select Event"}
                                className="counter-button counter-button-left"
                                onClick={() => updateCount(bookType, counts[bookType] - 1)}
                              >
                                -
                              </button>
                              <input
                                type="text"
                                className={"count-input text-center count-value-text " + bookType}
                                value={counts[bookType] || ""}
                                onChange={(e) => {
                                  const value = parseInt(e.target.value, 10);
                                  if (!isNaN(value) && value >= 0 && /^\d*$/.test(value)) {
                                    updateCount(bookType, value);
                                  } else if (e.target.value === "") {
                                    updateCount(bookType, ""); // Keep it empty if input is cleared
                                  }
                                }}
                              // readOnly={selectedEvent == "Select Event"}
                              />
                              <button
                                // disabled={selectedEvent == "Select Event"}
                                className="counter-button counter-button-right"
                                onClick={() => updateCount(bookType, counts[bookType] + 1)}
                              >
                                +
                              </button>
                            </td>
                            <td className="centered-cell">
                              <div className="pledge-pints mt-1">
                                {parseFloat(points[bookType]?.toFixed(2)).toLocaleString("en-IN")}&nbsp;
                                <span className="hide-points points-count">{t("Points")}</span>
                              </div>
                            </td>
                          </tr>
                        );
                      }

                      return null;
                    })}
                  </tbody>

                  <tfoot>
                    <tr>
                      <th scope="row">
                        {t("Total")} &nbsp;
                        {selectedEvent == "Regular Update" && !regularModify && submittedScoreFromDB.length > 0 ? (
                          <button
                            disabled={selectedEvent == "Select Event" || (selectedEvent == "Regular Update" && selectedDate == null) || loading}
                            className="btn-primarysave"
                            onClick={() => setRegularModify(true)}
                          >
                            {t("Modify")}
                          </button>
                        ) : (
                          <button
                            disabled={selectedEvent == "Select Event" || (selectedEvent == "Regular Update" && selectedDate == null) || loading}
                            className="btn-primarysave"
                            onClick={handleScoreSave}
                          >
                            {(selectedEvent == "Regular Update" && regularModify) || submittedScoreFromDB.length > 0 ? t("Update") : t("Save")}
                          </button>
                        )}
                      </th>
                      <td className="score-points">
                        <div className="total-table-points">{totalCounts.toLocaleString("en-IN")}</div>
                      </td>
                      <td>
                        <div className="total-table-points">{parseFloat(totalPoints.toFixed(2)).toLocaleString("en-IN")}</div>
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal centered show={show} onHide={handleHideModal}>
        <div className="modal-wrap">
          {modalPopUPText === "" ? (
            <p className="card-text p-3">
              {t("Are you sure you want to update the books count in")} {t(selectedEvent)} ?
            </p>
          ) : (
            <p className="card-text p-3">{t(modalPopUPText)}</p>
          )}
          <div className="d-flex justify-content-center mb-2 modal-gap">
            {modalPopUPText === "" && (
              <button className="btn-primarysave custom-button" onClick={() => handleShowPopModal()}>
                {t("Yes")}
              </button>
            )}
            <button className="btn-primarysave custom-button" onClick={() => setShow(false)}>
              {t("Cancel")}
            </button>
          </div>
        </div>
      </Modal>
      {/* MOdal For Bulk Upload Start  */}
      <Modale
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Upload File"
        className="Modal"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        overlayClassName="Overlay"
        style={{
          content: {
            position: "absolute",
            top: "100px",
            left: "0",
            right: "0",
            bottom: "0",
            padding: "20px",
            margin: "0",
            width: "100vw",
            height: "90vh",
            overflow: "auto",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >

        <div style={{
          fontFamily: 'Geologica, sans-serif',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '20px',
          backgroundColor: '#F9F9FD',
          borderRadius: '8px',
          position: 'relative',
          gap: '0px',
          opacity: '1',
        }}>

          <h2 style={{
            margin: '0 0 20px 0',
            color: '#43567D',
            fontSize: '26px',  // Updated font size
            fontWeight: '600', // Updated font weight
            lineHeight: '43px', // Updated line height
            textAlign: 'left'   // Updated text alignment
          }}>
            {`${t("You are Uploading")} ${t(selectedEvent)} ${t("Bulk Score")}`}
          </h2>

        </div>





        <div style={{
          // fontFamily: 'Arial, sans-serif',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '20px',
          backgroundColor: '#F9F9FD',
          borderRadius: '8px',
          marginTop: '-20px'
        }}>
          {/* Left side - File upload area */}
          <div style={{
            width: '45%',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
            }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.81635 22.3812H6.62396C3.26694 22.1414 1.75977 19.5608 1.75977 17.2657C1.75977 14.9706 3.26695 12.3786 6.56688 12.1503C7.03504 12.1046 7.44609 12.47 7.48035 12.9495C7.5146 13.4177 7.16068 13.8288 6.68111 13.863C4.46593 14.0229 3.47253 15.69 3.47253 17.2771C3.47253 18.8643 4.46593 20.5314 6.68111 20.6912H8.81635C9.28451 20.6912 9.67274 21.0795 9.67274 21.5476C9.67274 22.0158 9.28451 22.3812 8.81635 22.3812Z" fill="#43567D" />
                  <path d="M19.3338 22.3811C19.3109 22.3811 19.2995 22.3811 19.2767 22.3811C18.8085 22.3811 18.3746 21.9929 18.3746 21.5247C18.3746 21.0338 18.74 20.6684 19.2196 20.6684C20.6241 20.6684 21.8801 20.1774 22.8621 19.2982C24.6434 17.7452 24.7575 15.5072 24.278 13.9315C23.7984 12.3672 22.4624 10.5745 20.1331 10.289C19.7562 10.2433 19.4593 9.95788 19.3908 9.58107C18.9341 6.84064 17.4612 4.94518 15.2231 4.26007C12.9166 3.54071 10.2218 4.24865 8.54332 6.00709C6.91048 7.70844 6.53368 10.0949 7.48141 12.7211C7.64127 13.1665 7.41295 13.6574 6.96764 13.8173C6.52232 13.9772 6.0313 13.7488 5.87144 13.3035C4.71818 10.0835 5.24344 7.0005 7.31017 4.83099C9.42259 2.61581 12.8139 1.73659 15.7256 2.62722C18.3975 3.44935 20.2815 5.65311 20.9666 8.71325C23.2959 9.2385 25.1686 11.0084 25.9108 13.4519C26.7215 16.1124 25.9907 18.8528 23.9925 20.5884C22.725 21.7303 21.0694 22.3811 19.3338 22.3811Z" fill="#43567D" />
                  <path d="M14.0012 25.7382C11.706 25.7382 9.5594 24.5164 8.3833 22.541C8.25769 22.3469 8.13209 22.1185 8.02933 21.8673C7.6411 21.0566 7.43555 20.1317 7.43555 19.1726C7.43555 15.5529 10.3815 12.607 14.0012 12.607C17.6208 12.607 20.5668 15.5529 20.5668 19.1726C20.5668 20.1432 20.3612 21.0566 19.9502 21.9016C19.8588 22.1185 19.7332 22.3469 19.5962 22.5639C18.443 24.5164 16.2963 25.7382 14.0012 25.7382ZM14.0012 14.3198C11.3292 14.3198 9.14831 16.5007 9.14831 19.1726C9.14831 19.8805 9.29676 20.5428 9.58222 21.148C9.67357 21.3421 9.75347 21.502 9.84482 21.6504C10.7126 23.1234 12.2998 24.0254 13.9897 24.0254C15.6796 24.0254 17.2668 23.1234 18.1232 21.6732C18.226 21.502 18.3174 21.3421 18.3859 21.1822C18.6942 20.5542 18.8426 19.8919 18.8426 19.184C18.854 16.5007 16.6731 14.3198 14.0012 14.3198Z" fill="#43567D" />
                  <path d="M13.3492 21.1593C13.1323 21.1593 12.9153 21.0794 12.7441 20.9081L11.6136 19.7777C11.2825 19.4466 11.2825 18.8985 11.6136 18.5673C11.9447 18.2362 12.4928 18.2362 12.8239 18.5673L13.3721 19.1154L15.199 17.4255C15.553 17.1058 16.0896 17.1286 16.4093 17.4712C16.7291 17.8137 16.7062 18.3618 16.3637 18.6815L13.9316 20.931C13.7603 21.0794 13.5547 21.1593 13.3492 21.1593Z" fill="#43567D" />
                </svg>
                <span style={{
                  color: '#43567D',
                  // fontFamily: 'Inter',
                  fontSize: '22px',
                  fontWeight: '500',
                  lineHeight: '26.63px',
                  textAlign: 'left'
                }}>
                  {t("Upload file")} <br></br>
                  <p style={{
                    margin: '0 0 10px 0',
                    color: '#A9ACB4',
                    // fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '16.94px',
                    textAlign: 'left',
                    width: '277px',
                    height: '17px',
                    opacity: '1'
                  }}>
                    {t("Select and upload the file of your choice")}
                  </p>

                </span>

                <X
                  size={24}
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    border: "none",
                    background: "#9F1D21",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "18px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    color: "#fff",
                    lineHeight: "1",
                  }}
                  onClick={closeModal}
                />

              </div>

              <button style={{
                backgroundColor: '#8b0000',
                color: 'white',
                border: 'none',
                padding: '8px 20px',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '18px',
                // fontFamily: 'Geologica',
                fontWeight: '500',
                lineHeight: '22.5px',
                textAlign: 'center',

              }}
                onClick={downloadSampleExcel}>
                {t("Download Sample")}
              </button>
            </div>

            <div style={{
              border: '2px dashed #ccc',
              borderRadius: '8px',
              padding: '40px 20px',
              textAlign: 'center',
              marginBottom: '20px',
            }}>
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.81635 22.3812H6.62396C3.26694 22.1414 1.75977 19.5608 1.75977 17.2657C1.75977 14.9706 3.26695 12.3786 6.56688 12.1503C7.03504 12.1046 7.44609 12.47 7.48035 12.9495C7.5146 13.4177 7.16068 13.8288 6.68111 13.863C4.46593 14.0229 3.47253 15.69 3.47253 17.2771C3.47253 18.8643 4.46593 20.5314 6.68111 20.6912H8.81635C9.28451 20.6912 9.67274 21.0795 9.67274 21.5476C9.67274 22.0158 9.28451 22.3812 8.81635 22.3812Z" fill="#43567D" />
                <path d="M19.3338 22.3811C19.3109 22.3811 19.2995 22.3811 19.2767 22.3811C18.8085 22.3811 18.3746 21.9929 18.3746 21.5247C18.3746 21.0338 18.74 20.6684 19.2196 20.6684C20.6241 20.6684 21.8801 20.1774 22.8621 19.2982C24.6434 17.7452 24.7575 15.5072 24.278 13.9315C23.7984 12.3672 22.4624 10.5745 20.1331 10.289C19.7562 10.2433 19.4593 9.95788 19.3908 9.58107C18.9341 6.84064 17.4612 4.94518 15.2231 4.26007C12.9166 3.54071 10.2218 4.24865 8.54332 6.00709C6.91048 7.70844 6.53368 10.0949 7.48141 12.7211C7.64127 13.1665 7.41295 13.6574 6.96764 13.8173C6.52232 13.9772 6.0313 13.7488 5.87144 13.3035C4.71818 10.0835 5.24344 7.0005 7.31017 4.83099C9.42259 2.61581 12.8139 1.73659 15.7256 2.62722C18.3975 3.44935 20.2815 5.65311 20.9666 8.71325C23.2959 9.2385 25.1686 11.0084 25.9108 13.4519C26.7215 16.1124 25.9907 18.8528 23.9925 20.5884C22.725 21.7303 21.0694 22.3811 19.3338 22.3811Z" fill="#43567D" />
                <path d="M14.0012 25.7382C11.706 25.7382 9.5594 24.5164 8.3833 22.541C8.25769 22.3469 8.13209 22.1185 8.02933 21.8673C7.6411 21.0566 7.43555 20.1317 7.43555 19.1726C7.43555 15.5529 10.3815 12.607 14.0012 12.607C17.6208 12.607 20.5668 15.5529 20.5668 19.1726C20.5668 20.1432 20.3612 21.0566 19.9502 21.9016C19.8588 22.1185 19.7332 22.3469 19.5962 22.5639C18.443 24.5164 16.2963 25.7382 14.0012 25.7382ZM14.0012 14.3198C11.3292 14.3198 9.14831 16.5007 9.14831 19.1726C9.14831 19.8805 9.29676 20.5428 9.58222 21.148C9.67357 21.3421 9.75347 21.502 9.84482 21.6504C10.7126 23.1234 12.2998 24.0254 13.9897 24.0254C15.6796 24.0254 17.2668 23.1234 18.1232 21.6732C18.226 21.502 18.3174 21.3421 18.3859 21.1822C18.6942 20.5542 18.8426 19.8919 18.8426 19.184C18.854 16.5007 16.6731 14.3198 14.0012 14.3198Z" fill="#43567D" />
                <path d="M13.3492 21.1593C13.1323 21.1593 12.9153 21.0794 12.7441 20.9081L11.6136 19.7777C11.2825 19.4466 11.2825 18.8985 11.6136 18.5673C11.9447 18.2362 12.4928 18.2362 12.8239 18.5673L13.3721 19.1154L15.199 17.4255C15.553 17.1058 16.0896 17.1286 16.4093 17.4712C16.7291 17.8137 16.7062 18.3618 16.3637 18.6815L13.9316 20.931C13.7603 21.0794 13.5547 21.1593 13.3492 21.1593Z" fill="#43567D" />
              </svg>

              <p>{t("Choose a file or drag & drop it here")}</p>
              <p style={{ fontSize: '12px', color: '#666' }}>{t("Excel formats: XLSX, XLS")}</p>
              <input
                type="file" accept=".xlsx, .xls" style={{ display: "none" }} id="file-upload" onChange={handleFileChange}
              />

              <label htmlFor="file-upload" style={{
                backgroundColor: '#f0f0f0',
                padding: '10px 20px',
                borderRadius: '4px',
                cursor: 'pointer',
                display: 'inline-block',
                marginTop: '10px',
              }}>
                {t("Browse File")}
              </label>  {selectedFile && (
                <div style={{ marginTop: "20px" }}>
                  <p>
                    <strong>{t("Selected file:")}</strong> {selectedFile.name}
                  </p>
                </div>
              )}
            </div>

            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <button style={{
                backgroundColor: '#8b0000',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '18px',
                width: '20%',
                lineHeight: '22.5px',
                // fontFamily: 'Geologica',
                fontWeight: 500,
              }}
                onClick={handleUploadClick}>
                {t("Upload")}
              </button>
            </div>
            <div style={{ marginTop: '20px' }}>
              {files.map((file, index) => (
                <div key={index} style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#e9f7ef',
                  padding: '10px',
                  borderRadius: '4px',
                  marginBottom: '10px',
                }}>
                  <span style={{ backgroundColor: '#4CAF50', color: 'white', padding: '2px 6px', borderRadius: '4px', marginRight: '10px' }}>
                    {file.name.split('.').pop().toUpperCase()}
                  </span>
                  <span style={{ flexGrow: 1 }}>{file.name}</span>
                  <X onClick={() => removeFile(index)} style={{ cursor: 'pointer' }} />
                </div>
              ))}
            </div>
          </div>

          {/* Right side - Instructions */}
          <div style={{
            width: '54%',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            position: 'relative',
          }}>


            <h3 style={{
              margin: '0 0 20px 0', fontWeight: '600',
              lineHeight: '43px',
              // fontFamily: 'Montserrat', 
              fontSize: '22px', color: '#43567D'
            }}>{t("Instruction How To Upload The Data")}</h3>

            {[
              { step: 1, title: 'Book Name', description: 'In Book Name please add the name of the book with correct spelling.' },
              { step: 2, title: 'Book Count', description: 'In Book Count please add the book distribution count.' },
              { step: 3, title: 'Date fields', description: 'In the Date Fields please add the date for which you need to upload the data. The date format should be DD-MM-YYYY.' },
              { step: 4, title: 'Upload file', description: 'Upload file should be in the .xlsx format.' },
            ].map((item) => (
              <div key={item.step} style={{ marginBottom: '20px', display: 'flex' }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginRight: '15px',
                }}>
                  <div style={{
                    backgroundColor: '#8b0000',
                    color: 'white',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '22px',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                    lineHeight: '43px',
                    fontFamily: 'Montserrat',
                  }}>
                    {item.step}
                  </div>

                  <div style={{ fontSize: '12px', color: '#666' }}>
                    {t("Step")} {item.step}
                  </div>
                </div>
                <div style={{
                  backgroundColor: '#f0f0f0',
                  borderRadius: '8px',
                  padding: '15px',
                  flex: 1,
                }}>
                  <h4 style={{
                    margin: '0 0 5px 0',
                    color: '#43567D',
                    // fontFamily: 'Inter',
                    fontSize: '20px',
                    fontWeight: '600',
                    lineHeight: '24.2px',
                    textAlign: 'left',
                    // width: '110px', // Optional: Ensure it fits within your layout
                    // height: '24px', // Optional: Ensure it fits within your layout

                  }}>
                    {t(item.title)}
                  </h4>
                  <p style={{
                    margin: 0,
                    color: '#5F5F5F',
                    fontSize: '17px',
                  }}>
                    {t(item.description)}
                  </p>
                </div>

              </div>
            ))}
          </div>
        </div>
      </Modale>

      {/* Model For Bulk Upload End  */}
    </>
  );
};

export default RecordingOfScores;
